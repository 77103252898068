
  import { defineComponent, onMounted, ref, computed, watch } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import Multiselect from '@vueform/multiselect';

  export default defineComponent({
    name: 'product-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
      Multiselect,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedOffice = ref<Array<string>>([]);
      const selectedClient = ref<number>(0);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      watch(
        () => selectedClient.value,
        async () => {
          if (selectedClient.value) {
            selectedOffice.value = [];
            await store.dispatch(
              Actions.GET_SHIPMENT_GW_OFFICES,
              selectedClient.value
            );
          }
        }
      );

      const goBack = () => {
        router.back();
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('productCreating'), [
          translate('clients'),
        ]);
      });

      const iqdPrice = ref(0);
      const usdPrice = ref('');

      const currency = await store.dispatch(Actions.GET_CURRENCY);

      const iqdExchangeRate = currency.find(
        (item) => item.code === 'iqd'
      ).exchangeRate;

      const convertToIQ = (usdPrice) => {
        iqdPrice.value = usdPrice * iqdExchangeRate;
      };

      const shpmentSchema = Yup.object().shape({
        titleAr: Yup.string()
          .required(() => translate('AR_TITLE_IS_REQUIRED_FIELD'))
          .label('Title Ar'),
        titleEn: Yup.string()
          .required(() => translate('EN_TITLE_IS_REQUIRED_FIELD'))
          .label('Title En'),
        titleKu: Yup.string()
          .required(() => translate('KU_TITLE_IS_REQUIRED_FIELD'))
          .label('Title Ku'),
        descriptionAr: Yup.string()
          .required(() => translate('AR_DESCRIPTION_IS_REQUIRED_FIELD'))
          .label('Description Ar'),
        descriptionEn: Yup.string()
          .required(() => translate('EN_DESCRIPTION_IS_REQUIRED_FIELD'))
          .label('Description En'),
        descriptionKu: Yup.string()
          .required(() => translate('KU_DESCRIPTION_IS_REQUIRED_FIELD'))
          .label('Description Ku'),
        price: Yup.string()
          .required(() => translate('PRICE_IS_REQUIRED_FIELD'))
          .label('Price'),
        clientId: Yup.string()
          .required(() => translate('CLIENT_IS_REQUIRED_FIELD'))
          .label('clientId'),
      });
      const onSubmitCreate = async (values) => {
        values.name = [];
        values.description = [];
        values.name.push({ languageCode: 'en', title: values.titleEn });
        values.name.push({ languageCode: 'ar', title: values.titleAr });
        values.name.push({ languageCode: 'ku', title: values.titleKu });

        values.description.push({
          languageCode: 'ar',
          title: values.descriptionAr,
        });
        values.description.push({
          languageCode: 'en',
          title: values.descriptionEn,
        });
        values.description.push({
          languageCode: 'ku',
          title: values.descriptionKu,
        });

        delete values.titleEn;
        delete values.titleAr;
        delete values.titleKu;

        delete values.descriptionAr;
        delete values.descriptionEn;
        delete values.descriptionKu;

        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        let imageFormData: FormData | null = null;
        if (values.image) {
          imageFormData = new FormData();
          imageFormData.append('productImage', values.image);
        }
        delete values.image;

        values.officesIds = selectedOffice.value;
        values.price = Number(values.price);
        values.slug = 'product-one';
        values.isactive = true;

        delete values.priceIqd;

        const product = await store.dispatch(Actions.CREATE_PRODUCT, values);

        if (product?.id && imageFormData) {
          await store.dispatch(Actions.UPLOAD_PRODUCT_IMAGE, {
            data: imageFormData,
            productId: product.id,
          });
        }

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_PRODUCT'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'products-listing' });
          });
        }
      };
      const offices = computed(() => store.getters.gwOfficesList);
      console.log(offices.value);
      const officesTitle = () => {
        return offices.value
          .filter((r) => r.deletedat === null)
          .map((r) => ({ title: r.titleTranslate, id: r.id }));
      };

      await store.dispatch(Actions.GET_ALL_CLIENTS, { limit: 50 });

      return {
        clients: computed(() => store.getters.allClientsList),
        onSubmitCreate,
        convertToIQ,
        usdPrice,
        iqdPrice,
        officesTitle,
        selectedClient,
        selectedOffice,
        submitButton,
        translate,
        shpmentSchema,
        goBack,
        can,
      };
    },
  });
